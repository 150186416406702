import React from 'react'
import { Button } from '../../components/button'
import { AppState, CreationState, IoMsgType } from '../../constants'
import { IoEvent } from '../../interfaces'
import { actions, useDispatch, useTypedSelector } from '../../store'
import { OnlyDesktop } from '../../utils'
import { EditContainer } from '../edit-container'
import { UploadContainer } from '../upload-container'
import { FileSelectContainer } from '../video-select'
import styles from './styles.module.scss'

interface CrlProps {
  sendMessage: (event: IoEvent) => void
}

const Container: React.FC = (props) => (
  <div className={styles.container}>{props.children}</div>
)

const CallToAction: React.FC<{ onAction: () => void }> = (props) => {
  return (
    <div className={styles.cta}>
      <OnlyDesktop>
        <div>
          <p>
            "One of the best purchases
            <br />
            I've ever made!"
            <br />
            -Aishah S.
          </p>
        </div>
      </OnlyDesktop>

      <h1>
        Print video for
        <br />
        the first time.
        <br />
        starting at $100.
      </h1>

      <div>
        <Button onClick={props.onAction} label='Start creating' />
        <small>
          By using this tool you accept our{' '}
          <a
            href='https://infiniteobjects.com/pages/terms-conditions'
            target='_blank'
            rel='noopener'
          >
            Terms and Conditions.
          </a>
        </small>
      </div>
    </div>
  )
}

export const ControllersContainer: React.FC<CrlProps> = (props) => {
  const dispatch = useDispatch()
  const readyState = useTypedSelector((state) => state.readyState)
  const hasUploaded = useTypedSelector(
    ({ creation }) => creation.readyState >= CreationState.hasUploaded
  )

  const addToCart = () => {
    dispatch(actions.saveCreation())
      .then(() => dispatch(actions.setReadyState(AppState.previewCreation)))
      .then(() => props.sendMessage({ type: IoMsgType.addToCart }))
    // .then(() => dispatch(actions.setReadyState(AppState.previewCreation)))
  }

  const onFinishEdit = () => {
    if (!hasUploaded) dispatch(actions.setReadyState(AppState.uploadingVideo))
    else addToCart()
  }

  switch (readyState) {
    case AppState.pristine:
      return (
        <Container>
          <CallToAction
            onAction={() =>
              dispatch(actions.setReadyState(AppState.selectingVideo))
            }
          />
        </Container>
      )

    case AppState.selectingVideo:
      return (
        <Container>
          <FileSelectContainer
            onSelected={() =>
              dispatch(actions.setReadyState(AppState.editingCreation))
            }
          />
        </Container>
      )

    case AppState.loadingCreation:
      return <Container>Loading </Container>

    case AppState.editingCreation:
      return (
        <Container>
          <EditContainer
            onFinish={onFinishEdit}
            sendMessage={props.sendMessage}
          />
        </Container>
      )

    case AppState.uploadingVideo:
      return (
        <Container>
          <UploadContainer onUploadComplete={addToCart} />
        </Container>
      )

    default:
      return <React.Fragment />
  }
}
