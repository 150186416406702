import { isArray } from './typechecks'

/**
 * Returns a random element from an array.
 */
export function sample<T = unknown>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)]
}

/**
 * Returns true if the given Array is empty
 */
export function isEmptyArray(array: unknown): boolean {
  return isArray(array) && !array.length
}
