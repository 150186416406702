import { MutableRefObject } from 'react'

export type ClickEvent = Event & { target: HTMLButtonElement }

interface UseScroll {
  scrollContainerToHiddenElement: (
    event: ClickEvent,
    spaceBetween?: number
  ) => void
}

function sideScroll(
  element: HTMLDivElement,
  direction: 'left' | 'right',
  distance: number
) {
  let scrollAmount = 0
  const step = 10
  const slideTimer = setInterval(function () {
    if (direction === 'left') {
      element.scrollLeft -= step
    } else {
      element.scrollLeft += step
    }

    scrollAmount += step

    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer)
    }
  }, 25)
}

function getDistance(
  elementWidth: number,
  elementVisibleWidth: number,
  spaceBetween: number
) {
  return elementWidth - elementVisibleWidth + spaceBetween + elementWidth / 3
}

export function useScroll(
  ref: MutableRefObject<HTMLDivElement | null>
): UseScroll {
  const scrollContainerToHiddenElement = (
    event: ClickEvent,
    spaceBetween = 0
  ) => {
    if (!event.target) return

    const containerRef = ref.current
    if (!containerRef) return

    const containerRect = containerRef.getBoundingClientRect()
    const elementRect = event.target.getBoundingClientRect()

    if (
      containerRect.left <= elementRect.left &&
      containerRect.right >= elementRect.right
    ) {
      return
    }

    if (containerRect.right < elementRect.right) {
      const elementVisibleWidth = containerRect.right - elementRect.left
      const distance = getDistance(
        elementRect.width,
        elementVisibleWidth,
        spaceBetween
      )

      sideScroll(containerRef, 'right', distance)
    } else {
      const elementVisibleWidth = elementRect.right - containerRect.left
      const distance = getDistance(
        elementRect.width,
        elementVisibleWidth,
        spaceBetween
      )

      sideScroll(containerRef, 'left', distance)
    }
  }

  return { scrollContainerToHiddenElement }
}
