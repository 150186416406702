import { Reducer } from 'redux'
import { ActionType, TypedAction, WithNull } from '../../interfaces'

export interface LocalState {
  videoFile: WithNull<File>
  videoUrl: WithNull<string>
  isTrimming: boolean
  isLoading: boolean
  currentTime: number
  isMobile: boolean
}

const initialState: LocalState = {
  videoFile: null,
  videoUrl: null,
  isTrimming: false,
  isLoading: false,
  currentTime: 0,
  isMobile: false,
}

export const localReducer: Reducer<LocalState, TypedAction> = (
  state = initialState,
  action
): LocalState => {
  switch (action.type) {
    /* Set current time in the video */
    case ActionType.setCurrentTime:
      return { ...state, currentTime: action.payload }

    /* Set Loading state */
    case ActionType.isLoading:
      return { ...state, isLoading: action.payload }

    /* Set current trimming State */
    case ActionType.isTrimming:
      return { ...state, isTrimming: action.payload }

    /* Set mobile status */
    case ActionType.setMobile:
      return { ...state, isMobile: action.payload }

    /* Select video */
    case ActionType.selectVideo:
      return {
        ...state,
        isTrimming: false,
        isLoading: false,
        currentTime: 0,
        videoFile: action.payload.file,
        videoUrl: action.payload.metadata.url,
      }

    case ActionType.resetVideo:
      return {
        ...state,
        isTrimming: false,
        isLoading: false,
        currentTime: 0,
        videoFile: null,
        videoUrl: null,
      }

    default:
      return state
  }
}
