import React from 'react'
import styles from './modal.module.scss'
import closeIcon from '../../assets/icons/close.svg'

interface ModalProps {
  isOpen: boolean
  onExit: () => void
}

export const Modal: React.FC<ModalProps> = (props) => {
  if (!props.isOpen) return null

  return (
    <div className={styles.modalBackdrop} onClick={props.onExit}>
      <div className={styles.modal} onClick={props.onExit}>
        <div className={styles.modalContent}>{props.children}</div>
        <img
          className={styles.closeIcon}
          src={closeIcon}
          alt='close'
          onClick={props.onExit}
        />
      </div>
    </div>
  )
}
