import React, { useCallback, useContext, useRef } from 'react'
import { IoOrientation, MAX_TEXTURE_SIZE } from '../../constants'
import { PreviewContext } from '../../contexts/preview'
import { useEventListener } from '../../hooks/use-event'
import { ScreenPosition, Size } from '../../interfaces'
import { actions, useDispatch, useTypedSelector } from '../../store'
import { asClassName, asPx, round, useDebug } from '../../utils'
import styles from './video-component.module.scss'

const PRECISION = 4

interface VideoElementProps {
  containerSize: Size
}

export const VideoElement: React.FC<VideoElementProps> = (props) => {
  /* Get a reference to the actual video element */
  const vidRef = useRef<HTMLVideoElement | null>(null)
  const debug = useDebug('video')

  const { frame, video } = useContext(PreviewContext)
  const { containerSize } = props
  const dispatch = useDispatch()

  const videoUrl = useTypedSelector((state) => state.local.videoUrl)
  const originalUrl = useTypedSelector(
    (state) => state.creation.video.originalUrl
  )
  const thumbUrl = useTypedSelector(
    (state) => state.creation.video.thumbnailUrl
  )
  const optimizedUrl = useTypedSelector(
    (state) => state.creation.video.optimizedUrl
  )
  const isTrimming = useTypedSelector((state) => state.local.isTrimming)
  const isLoading = useTypedSelector((state) => state.local.isLoading)
  const duration = useTypedSelector(
    (state) => state.creation.video.metadata.duration
  )

  const orientation = useTypedSelector(
    (state) => state.creation.device.orientation
  )
  const translate = useTypedSelector(
    (state) => state.creation.transform.translate
  )
  const scale = useTypedSelector((state) => state.creation.transform.scale)
  const rotate = useTypedSelector((state) => state.creation.transform.rotate)
  const speed = useTypedSelector((state) => state.creation.transform.speed)
  const currentTime = useTypedSelector((state) =>
    round(state.local.currentTime * duration, PRECISION)
  )
  const startTime = useTypedSelector((state) =>
    round(state.creation.transform.startTime * duration, PRECISION)
  )
  const endTime = useTypedSelector((state) =>
    round(state.creation.transform.endTime * duration, PRECISION)
  )

  /** Video Transform is applied to the videoElement  */
  const videoTransform = useCallback(() => {
    const { width, height } = getSize(containerSize, frame, video, orientation)
    const { x, y } = translate
    const tr = `translate(${asPx(x * width)}, ${asPx(y * height)})`
    const sc = `scale(${scale})`
    const rt = `rotate(${rotate}deg)`

    return { transform: `${tr} ${sc} ${rt}` }
  }, [containerSize, frame, video, orientation, translate, scale, rotate])

  let lastSet = 0
  const setTime = (time: number, debounce = true) => {
    if (!vidRef.current) return

    /* Return if is being called with the same value */
    if (lastSet === time && debounce) {
      debug('Set time called with the same value in less than 500ms')
      return
    }
    /* Return if the target time is too close to the current time */
    if (round(vidRef.current.currentTime, 1) === round(time, 1)) {
      debug(
        'Target time (%s) is in rounding distance of current time (%s)',
        vidRef.current.currentTime,
        time
      )
      return
    }

    /* Set the time */
    debug('Setting current time: %s', time)
    vidRef.current.currentTime = time

    /* Poorman's debounce */
    lastSet = time
    setTimeout(() => (lastSet = 0), 500)
  }

  useEventListener(
    'loadstart',
    () => (!isLoading ? dispatch(actions.isLoading(true)) : null),
    vidRef
  )
  useEventListener(
    'canplaythrough',
    () => (isLoading ? dispatch(actions.isLoading(false)) : null),
    vidRef
  )
  useEventListener('error', (err) => console.error(err), vidRef)
  useEventListener(
    'seeked',
    () => debug('Seeked to %s', vidRef.current?.currentTime),
    vidRef
  )

  /* Trim and loops */
  useEventListener(
    'timeupdate',
    () => {
      if (!vidRef.current) return // Return if no vidRef

      const loop =
        vidRef.current.currentTime < startTime ||
        vidRef.current.currentTime >= endTime

      if (loop) {
        debug('Attempting to loop')
        /* return if is trimming */
        if (isTrimming) {
          debug('No loop: User is Trimming')
          return
        }
        /* return if has not completed the seeking operations */
        if (vidRef.current.seeking) {
          debug('No loop: Video is Seeking')
          return
        }
        /* Trim start */
        if (vidRef.current.currentTime < startTime) {
          debug('Loop trim start: %s', vidRef.current.currentTime)
          setTime(startTime)
          return
        }
        /* Trim end */
        if (vidRef.current.currentTime >= endTime) {
          debug('Loop trim end %s', vidRef.current.currentTime)
          setTime(startTime, false)
        }
      }
    },
    vidRef
  )

  /* Do this on every loop */
  if (vidRef.current && isLoading === false) {
    if (isTrimming) {
      /* If is not paused pause the video first */
      if (!vidRef.current.paused) vidRef.current.pause()
      setTime(currentTime)
    } else {
      /* If is paused play the video */
      if (vidRef.current.paused) vidRef.current.play()
    }
    /* Speed */
    if (vidRef.current.playbackRate !== speed) {
      debug('Setting playback rate to %s', speed)
      vidRef.current.playbackRate = speed
    }
  }

  return (
    <video
      ref={vidRef}
      className={asClassName(
        styles.videoPreview,
        orientation === IoOrientation.portrait
          ? styles.portrait
          : styles.landscape
      )}
      style={videoTransform()}
      muted
      autoPlay
      loop
      playsInline
      poster={thumbUrl || undefined}
    >
      {videoUrl ? <source src={videoUrl} /> : null}
      {optimizedUrl ? <source src={optimizedUrl} /> : null}
      {originalUrl ? <source src={originalUrl} /> : null}
    </video>
  )
}

function getSize(
  containerSize: Size,
  frame: ScreenPosition,
  video: Size,
  orientation: IoOrientation
): Size {
  const frameVertical = {
    width: containerSize.height * (frame.width / frame.height),
    height: containerSize.height,
  }
  const frameHorizontal = {
    width: containerSize.width,
    height: containerSize.width * (frame.height / frame.width),
  }

  const frameSize =
    orientation === IoOrientation.portrait
      ? frameVertical.width > containerSize.width
        ? frameHorizontal
        : frameVertical
      : frameHorizontal.height > containerSize.height
      ? frameVertical
      : frameHorizontal

  let textureSize = frameSize.width / frame.width
  if (textureSize > MAX_TEXTURE_SIZE) {
    textureSize = MAX_TEXTURE_SIZE
  }
  const width = textureSize * video.width
  const height = textureSize * video.height

  const size = {
    width,
    height,
  }

  return size
}
