import React, { cloneElement, ReactElement, useRef } from 'react'
import { ClickEvent, useScroll } from '../../hooks/use-scroll'

import styles from './swiper.module.scss'

interface SwiperProps {
  spaceBetween?: number
  children: ReactElement | ReactElement[]
}

export const Swiper: React.FC<SwiperProps> = (props: SwiperProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { scrollContainerToHiddenElement } = useScroll(ref)

  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.slides}>
        {React.Children.map(props.children, (child) => {
          return cloneElement(child, {
            onClick: (event: ClickEvent) => {
              child.props.onClick()
              scrollContainerToHiddenElement(event, props.spaceBetween)
            },
          })
        })}
      </div>
    </div>
  )
}
