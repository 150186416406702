import React from 'react'
import { useHammer } from '../../hooks/use-hammer'
import { Size } from '../../interfaces'
import { actions, useDispatch } from '../../store'
import styles from './styles.module.scss'

export const PositionController: React.FC<{ containerPos: Size }> = (props) => {
  const dispatch = useDispatch()
  const ref = useHammer({
    onPan: ({ deltaX, deltaY }) => {
      dispatch(
        actions.setTranslation({
          x: deltaX / props.containerPos.width,
          y: deltaY / props.containerPos.height,
        })
      )
    },
  })

  return <div ref={ref} className={styles.container} />
}
