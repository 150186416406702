import React, { useEffect } from 'react'
import { IoMsgType } from './constants'
import { ControllersContainer } from './containers/controllers-container'
import { PreviewContainer } from './containers/preview-container'
import { useCreationId } from './hooks/use-creation-id'
import { useFirebaseAuth } from './hooks/use-firebase-auth'
import { useMessages } from './hooks/use-messages'
import { useMobileDetection } from './hooks/use-mobile-detection'
import { useFirebase } from './services/firebase'
import { useTypedSelector } from './store'
import { useTimeout } from './utils'

export const Application: React.FC = () => {
  useMobileDetection()

  const firebaseInit = useFirebase()
  const sendMessage = useMessages()

  const hasUser = useFirebaseAuth(firebaseInit)
  const [hasFetchID, foundCreation, hasCreationID] = useCreationId(firebaseInit)
  const time = hasCreationID ? 2000 : 1000
  const preload = useTimeout(time)
  const version = useTypedSelector((state) => state.version)
  const hasVariants = useTypedSelector((s) => s.shopify.variants.length > 0)

  const conditions = [preload, firebaseInit, hasUser]

  if (hasCreationID) conditions.push(hasFetchID, foundCreation)
  else conditions.push(hasVariants)

  const isLoading = conditions.some((v) => v === false)

  useEffect(() => {
    if (!hasVariants) sendMessage({ type: IoMsgType.ready })
    else sendMessage({ type: IoMsgType.variantReady })
  }, [hasVariants, sendMessage])

  if (isLoading) {
    if (hasFetchID && !foundCreation) {
      return (
        <div className='loading'>
          <div className='brand' />
          <pre className='version'>V{version} Beta</pre>
          <div className='loader'>
            <div className='spinner' />
          </div>
          <pre>Cannot load the creation</pre>
        </div>
      )
    }

    return (
      <div className='loading'>
        <div className='brand' />
        <pre className='version'>V{version} Beta</pre>
        <div className='loader'>
          <div className='spinner' />
        </div>
        <pre>loading application</pre>
      </div>
    )
  }

  return (
    <div className='application'>
      <PreviewContainer />
      <ControllersContainer sendMessage={sendMessage} />
    </div>
  )
}
