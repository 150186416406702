import { useEffect, useState } from 'react'
import { actions, useDispatch } from '../store'

/**
 * Hooks the window size to the state
 */

export function useFirebaseAuth(hasInit: boolean): boolean {
  const [hasUser, setUser] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!hasInit) return
    if (hasUser) return
    dispatch(actions.logIn()).then(() => setUser(true))
  }, [dispatch, hasInit, hasUser])

  return hasUser
}
