import { ALLOWED_ORIGINS, MESSAGE_TYPES } from '../constants'
import { IoMessageEvent, ObjectLike } from '../interfaces'

/** Checks if the given input is an Array */
export function isArray<T = unknown>(val: unknown): val is T[] {
  return Array.isArray(val)
}

/** Checks if the given input is an a number */
export function isNumber(x: unknown): x is number {
  return typeof x === 'number'
}

/** Checks if the given input is an a string */
export function isString(x: unknown): x is string {
  return typeof x === 'string'
}

/** Checks if the given input is a Date */
export function isDate(x: unknown): x is Date {
  return x instanceof Date && !isNaN(x.valueOf())
}

/**
 * Checks if the given input is a HTMLElement
 * You can use Generic types to assert an specific Element Type
 * @remarks No actual check for the type assertion is done
 */
export function isElement<E extends HTMLElement>(elem: E | null): elem is E {
  return !!(elem && elem.addEventListener)
}

/**
 * Return true if the given input is an Object
 */
export function isObject(obj: unknown): obj is ObjectLike {
  return obj !== null && typeof obj === 'object' && !isArray(obj)
}

export function isIoMessage(event: MessageEvent): event is IoMessageEvent {
  return (
    ALLOWED_ORIGINS.includes(event.origin) &&
    MESSAGE_TYPES.includes(event.data.type)
  )
}
