import React, { useEffect, useMemo, useState } from 'react'
import { Confirm } from '../../components/confirmation'
import FileInput from '../../components/file-input'
import {
  IoOrientation,
  IO_HEIGHT_7,
  IO_WIDTH_7,
  MAX_UPLOAD_SIZE,
} from '../../constants'
import { FileMeta } from '../../interfaces'
import { actions, useDispatch } from '../../store'
import { prettyBytes } from '../../utils'
import styles from './styles.module.scss'

interface VideoUsability {
  usable: boolean
  warning: boolean
  reason: 'FORMAT' | 'SIZE' | 'DIMENSIONS'
}

interface FileSelectProps {
  onFile: (file: File | null) => void
}

interface ContainerProps {
  onSelected: () => void
}

// ──────────────────────────────  components  ─────────────────────────────────

const FileSelect: React.FC<FileSelectProps> = (props) => {
  return (
    <div className={styles.container}>
      <h2>
        Video
        <br />
        Requirements
      </h2>
      <div className={styles.copy}>
        <ul>
          <li>File format: MP4 or MOV</li>
          <li>Max file size: {prettyBytes(MAX_UPLOAD_SIZE)}</li>
          <li>
            Min suggested resolution: {IO_WIDTH_7}x{IO_HEIGHT_7}
          </li>
        </ul>
      </div>
      <FileInput onFile={props.onFile} />
      <small>
        {'Check out our '}
        <a
          href='https://infiniteobjects.com/pages/faq'
          target='_blank'
          rel='noopener'
        >
          F.A.Q.
        </a>
        {' or '}
        <a
          href='https://infiniteobjects.com/pages/tips'
          target='_blank'
          rel='noopener'
        >
          Tips
        </a>
        {' for more info.'}
      </small>
    </div>
  )
}

export const FileSelectContainer: React.FC<ContainerProps> = (props) => {
  const [file, setFile] = useState<File | null>()
  const [fileMeta, setFileMeta] = useState<FileMeta>()
  const dispatch = useDispatch()

  const reset = () => {
    dispatch(actions.resetVideo())
    if (fileMeta) URL.revokeObjectURL(fileMeta.url)
    setFile(null)
    setFileMeta(undefined)
  }

  const usability = useMemo(() => canUseFile(fileMeta), [fileMeta])

  useEffect(() => {
    if (!file) return
    getVideoMeta(file).then((data) => setFileMeta(data))
  }, [file])

  useEffect(() => {
    if (!file) return
    if (!fileMeta) return
    dispatch(actions.selectVideo(file, fileMeta))
  }, [dispatch, file, fileMeta])

  if (!file) return <FileSelect onFile={setFile} />

  if (!fileMeta)
    return (
      <div className={styles.container}>
        <pre className={styles.processing}>Processing file</pre>
      </div>
    )
  const header = usability.usable
    ? usability.reason === 'DIMENSIONS'
      ? 'Warning'
      : 'Very Nice!'
    : 'Sorry!'

  const confirmLabel =
    usability.reason === 'DIMENSIONS' ? 'Continue anyway' : 'Continue'

  return (
    <Confirm
      header={header}
      canConfirm={usability.usable}
      confirmLabel={confirmLabel}
      warning={usability.reason === 'DIMENSIONS'}
      cancelLabel='Change Video'
      onConfirm={props.onSelected}
      onCancel={reset}
    >
      <div className={styles.copy}>
        {!usability.usable ? (
          usability.reason === 'FORMAT' ? (
            <p>
              This file type is not accepted.
              <br />
              Please select an MP4 or MOV video file.
            </p>
          ) : (
            <p>
              The filesize is bigger than the maximum allowed.
              <br />
              Please select a file smaller than {prettyBytes(MAX_UPLOAD_SIZE)}
            </p>
          )
        ) : usability.reason === 'DIMENSIONS' ? (
          <p>
            Your video is smaller than the reccomended size. Please select a
            video bigger than {IO_WIDTH_7}x{IO_HEIGHT_7}
          </p>
        ) : (
          <p>
            Next, let’s start designing your very own
            <br />
            Infinite Objects Video Print.
          </p>
        )}
      </div>
    </Confirm>
  )
}

// ────────────────────────────────  Helpers  ──────────────────────────────────

async function getVideoMeta(file: File): Promise<FileMeta> {
  return new Promise((resolve) => {
    const url = URL.createObjectURL(file) // eslint-disable-line compat/compat
    const vidElem = document.createElement('video')

    vidElem.setAttribute('preload', 'metadata')
    vidElem.addEventListener('error', (err) => {
      console.error(err)
      resolve({
        url,
        format: file.type,
        fileSize: file.size,
        size: { width: 0, height: 0 },
        duration: 0,
        orientation: IoOrientation.portrait,
      })
    })
    vidElem.addEventListener('loadedmetadata', () => {
      const { videoWidth, videoHeight, duration } = vidElem
      resolve({
        url,
        format: file.type,
        fileSize: file.size,
        size: { width: videoWidth, height: videoHeight },
        duration: duration,
        orientation:
          videoWidth > videoHeight
            ? IoOrientation.landscape
            : IoOrientation.portrait,
      })
    })

    vidElem.setAttribute('src', url)
  })
}

function canUseFile(meta?: FileMeta): VideoUsability {
  const result: VideoUsability = {
    usable: false,
    warning: false,
    reason: 'FORMAT',
  }
  if (!meta) return result
  if (!meta.size.height || !meta.size.width || !meta.duration) return result
  if (meta.fileSize > MAX_UPLOAD_SIZE) return { ...result, reason: 'SIZE' }

  if (meta.orientation === IoOrientation.landscape) {
    if (meta.size.height < IO_HEIGHT_7)
      return { usable: true, warning: true, reason: 'DIMENSIONS' }
    if (meta.size.width < IO_WIDTH_7)
      return { usable: true, warning: true, reason: 'DIMENSIONS' }
  }
  if (meta.orientation === IoOrientation.portrait) {
    if (meta.size.width < IO_HEIGHT_7)
      return { usable: true, warning: true, reason: 'DIMENSIONS' }
    if (meta.size.height < IO_WIDTH_7)
      return { usable: true, warning: true, reason: 'DIMENSIONS' }
  }

  return { ...result, usable: true }
}
