import { ThunkAction } from 'redux-thunk'
import {
  AppState,
  Filter,
  IoMaterial,
  IoOrientation,
  IoSize,
  Loop,
} from '../constants'
import {
  ActionType,
  FileMeta,
  TypedAction,
  User,
  Variant,
  Vector2D,
} from '../interfaces'
import {
  getCreationById,
  logInToFirebase,
  storeCreation,
} from '../services/firebase'
import { State } from './reducers'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ThunkActionCreator<Result = any> = ThunkAction<
  Promise<Result>,
  State,
  undefined,
  TypedAction
>

// ────────────────────────────  GLOBAL ACTIONS  ───────────────────────────────

export function setReadyState(state: AppState): ThunkActionCreator<void> {
  return async (dispatch, _getState) => {
    dispatch({
      type: ActionType.setReadyState,
      payload: state,
    })
  }
}

function setUser(payload: User): TypedAction {
  return {
    type: ActionType.setUser,
    payload,
  }
}

export function logIn(): ThunkActionCreator<void> {
  return async (dispatch, getState) => {
    const { user } = getState()
    if (user) return Promise.resolve()
    return logInToFirebase().then((user) => {
      if (user) dispatch(setUser(user))
    })
  }
}

// ───────────────────────────  Creation Actions  ──────────────────────────────

export function saveCreation(): ThunkActionCreator<boolean> {
  return async (_dispatch, getState) => {
    const { creation, user } = getState()
    return storeCreation(creation, user)
  }
}

export function getCreation(id: string): ThunkActionCreator<boolean> {
  return async (dispatch, _getState) => {
    const creation = await getCreationById(id)
    if (!creation) return false
    dispatch({ type: ActionType.loadCreation, payload: creation })
    return true
  }
}

// ────────────────────────────  Device Actions  ───────────────────────────────
export function setOrientation(orientation: IoOrientation): TypedAction {
  return {
    type: ActionType.setOrientation,
    payload: orientation,
  }
}

export function setSize(size: IoSize): TypedAction {
  return {
    type: ActionType.setSize,
    payload: size,
  }
}

export function setMaterial(material: IoMaterial): TypedAction {
  return {
    type: ActionType.setMaterial,
    payload: material,
  }
}

// ─────────────────────────────  Video Actions  ───────────────────────────────

export function resetVideo(): TypedAction {
  return {
    type: ActionType.resetVideo,
  }
}

export function selectVideo(file: File, metadata: FileMeta): TypedAction {
  return {
    type: ActionType.selectVideo,
    payload: { file, metadata },
  }
}

export function setOriginalResource(original: string): TypedAction {
  return {
    type: ActionType.setOriginalResource,
    payload: original,
  }
}

// ───────────────────────────  Video transforms  ──────────────────────────────

export function setFilter(filter: Filter): TypedAction {
  return {
    type: ActionType.setFilter,
    payload: filter,
  }
}

export function setRotation(angle: number): TypedAction {
  return {
    type: ActionType.setRotation,
    payload: { angle },
  }
}

export function addRotationClamp(): TypedAction {
  return {
    type: ActionType.setRotation,
    payload: { angle: 90, clamp: true, add: true },
  }
}

export function setSpeed(speed: number): TypedAction {
  return {
    type: ActionType.setSpeed,
    payload: speed,
  }
}

export function setTranslation(translate: Vector2D): TypedAction {
  return {
    type: ActionType.setTranslation,
    payload: translate,
  }
}

export function setScale(scale: number): TypedAction {
  return {
    type: ActionType.setScale,
    payload: scale,
  }
}

export function setTrim(trim: [number, number]): TypedAction {
  return {
    type: ActionType.setTrim,
    payload: trim,
  }
}

export function setLoop(loop: Loop): TypedAction {
  return {
    type: ActionType.setLoop,
    payload: loop,
  }
}

export function resetTransform(): TypedAction {
  return {
    type: ActionType.resetTransforms,
  }
}

// ────────────────────────────────  Shopify  ──────────────────────────────────

export function setVariantData(variants: Variant[]): ThunkActionCreator<void> {
  return async (dispatch, _getState) => {
    const sorted = variants.sort(sortByPrice)
    const maxPrice = variants.reduce(highestPriceAcc, 0)
    const minPrice = variants.reduce(lowestPriceAcc, maxPrice)
    dispatch(setVariants(sorted))
    dispatch(setMinPrice(minPrice))
  }
  /** Accumulator function to get the lowest available price */
  function lowestPriceAcc(current: number, variant: Variant): number {
    if (!variant.available) return current
    const price = parseFloat(variant.price)
    if (price < current) return price
    return current
  }

  /** Accumulator function to find the highest available price */
  function highestPriceAcc(current: number, variant: Variant): number {
    if (!variant.available) return current
    const price = parseFloat(variant.price)
    if (price > current) return price
    return current
  }

  function sortByPrice(a: Variant, b: Variant) {
    const priceA = parseFloat(a.price)
    const priceB = parseFloat(b.price)
    if (priceA < priceB) return 1
    if (priceA > priceB) return -1
    return 0
  }
}

export function setVariantById(id: string): ThunkActionCreator<void> {
  return async (dispatch, getState) => {
    const { shopify } = getState()
    const variant = shopify.variants.find((v) => v.id === id)
    if (!variant) return
    dispatch(setMaterial(variant.material))
    dispatch(setSize(variant.size))
  }
}

export function setVariants(variants: Variant[]): TypedAction {
  return {
    type: ActionType.setVariants,
    payload: variants,
  }
}

export function setMinPrice(price: number): TypedAction {
  return {
    type: ActionType.setMinPrice,
    payload: price,
  }
}

// ─────────────────────────────────  Local  ───────────────────────────────────

export function isTrimming(isTrimming: boolean): TypedAction {
  return {
    type: ActionType.isTrimming,
    payload: isTrimming,
  }
}

export function isLoading(isLoading: boolean): TypedAction {
  return {
    type: ActionType.isLoading,
    payload: isLoading,
  }
}

export function setCurrentTime(time: number): TypedAction {
  return {
    type: ActionType.setCurrentTime,
    payload: time,
  }
}

export function setMobile(size: boolean): TypedAction {
  return {
    type: ActionType.setMobile,
    payload: size,
  }
}
