import { Debug } from './debug'

const debug = Debug('env')

/**
 * Returns true if the given key exists in the environment
 */
export function hasEnv(key: string): boolean {
  const env = process?.env[key] !== undefined
  if (!env) debug('%s - not found', key)
  return env
}

/**
 * Gets the environment key or the default value
 * @throws if env.key doesn't exists and no default value
 */
export function getEnv(key: string, defaultValue?: string): string {
  const hasKey = hasEnv(key)
  if (hasKey) return process.env[key] as string
  if (defaultValue === undefined)
    throw new Error(`Cannot find variable "${key}" in the Environment`)
  return defaultValue
}

/**
 * Gets the environment REACT_APP_$key or the default value
 * @throws if env.key doesn't exists and no default value
 */
export function getReactEnv(key: string, defaultValue?: string): string {
  return getEnv(`REACT_APP_${key}`, defaultValue)
}
