import { combineReducers } from 'redux'
import { readyStateReducer, userReducer, versionReducer } from './app'
import { creationReducer } from './creation'
import { localReducer } from './local'
import { shopifyReducer } from './shopify'
export const rootReducer = combineReducers({
  user: userReducer,
  creation: creationReducer,
  shopify: shopifyReducer,
  local: localReducer,
  readyState: readyStateReducer,
  version: versionReducer,
})

export type State = ReturnType<typeof rootReducer>
