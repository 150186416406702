import { useEffect, useRef, useState } from 'react'
import { hasWindow, isMobile } from './context'
import { Debug } from './debug'

const voidFunction = (): void => {
  // Void
}

/**
 * Returns true id the screensize is in the mobile space
 * Hooks to the resize event
 */
export function useMobile(): boolean {
  const initial = isMobile()
  const debug = useDebug('hooks:mobile')
  const [mobile, setMobile] = useState(initial)
  useEffect(() => {
    if (!hasWindow()) return () => undefined
    window.addEventListener('resize', handleSize)
    return () => window.removeEventListener('resize', handleSize)
    function handleSize() {
      const mobileStatus = isMobile()
      debug(mobileStatus)
      setMobile(mobileStatus)
    }
  }, [debug])
  return mobile
}

/**
 * Creates a Debug instance
 * @param namespace - Name of the debug instance
 */
export function useDebug(namespace: string): debug.Debugger {
  const debugInstance = useRef(Debug(namespace))

  useEffect(() => {
    debugInstance.current = Debug(namespace)
    return () => {
      debugInstance.current.destroy()
    }
  }, [namespace])

  return debugInstance.current
}

export function useTimeout(amount = 1000): boolean {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    if (mounted) return
    const timeout = setTimeout(() => setMounted(true), amount)
    return () => clearTimeout(timeout)
  }, [amount, mounted])
  return mounted
}

export function useInterval(callback: () => void, delay?: number): void {
  const savedCallback = useRef(voidFunction)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return voidFunction
  }, [delay])
}
