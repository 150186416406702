import React from 'react'
import styles from './size-label.module.scss'
interface SizeLabelProps {
  onClick?: () => void
}

export const SizeLabel: React.FC<SizeLabelProps> = (props) => {
  return (
    <React.Fragment>
      <span>size </span>
      {'('}
      <span className={styles.sizeLink} onClick={props.onClick}>
        compare
      </span>
      {' )'}
    </React.Fragment>
  )
}

export const ResetLabel: React.FC<SizeLabelProps> = (props) => {
  return (
    <React.Fragment>
      <span>Edit </span>
      {'('}
      <span className={styles.sizeLink} onClick={props.onClick}>
        reset
      </span>
      {' )'}
    </React.Fragment>
  )
}
