import { useCallback, useEffect, useState } from 'react'
import { actions, useDispatch } from '../store'
import { hasWindow, isMobile, useDebug } from '../utils'

/**
 * Hooks the window size to the state
 */

export function useMobileDetection(): void {
  const dispatch = useDispatch()
  const debug = useDebug('app:screen')
  const [mobile, setMobile] = useState()

  const updateMobile = useCallback(() => {
    const current = isMobile()
    if (current === mobile) return
    if (current) debug('Mobile Screen')
    else debug('Desktop Screen')
    setMobile(mobile)
    dispatch(actions.setMobile(current))
  }, [debug, dispatch, mobile])

  // Recalculate on init
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateMobile, [])
  // Recalculate on window resize
  useEffect(() => {
    if (!hasWindow()) return () => undefined
    window.addEventListener('resize', updateMobile)
    return () => window.removeEventListener('resize', updateMobile)
  }, [updateMobile])
}
