import { ActionType, ShopifyState, TypedAction } from '../../interfaces'

const initialState: ShopifyState = {
  cart: null,
  product: null,
  variants: [],
  minPrice: 0,
}

/**
 * State reducer: takes the current state and the given accion, returns an updated state
 */
export function shopifyReducer(
  state = initialState,
  action: TypedAction
): ShopifyState {
  switch (action.type) {
    // case ActionType.setCart:
    //   return { ...state, cart: action.payload }
    // case ActionType.setProduct:
    //   return { ...state, product: action.payload }
    case ActionType.setVariants:
      return { ...state, variants: action.payload }
    case ActionType.setMinPrice:
      return { ...state, minPrice: action.payload }

    default:
      return state
  }
}
