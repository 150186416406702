import React, { useContext } from 'react'
import { IoOrientation, MAX_TEXTURE_SIZE } from '../../constants'
import { PreviewContext, PreviewInterface } from '../../contexts/preview'
import { ScreenPosition, Size } from '../../interfaces'
import { useTypedSelector } from '../../store'
import { positionAsCSS, useDebug } from '../../utils'

import styles from './preview-image.module.scss'

interface ImageProps {
  containerSize: Size
  layer: 'background' | 'device' | 'matte'
}

export const PreviewImage: React.FC<ImageProps> = ({
  layer,
  containerSize,
}) => {
  const ctx = useContext(PreviewContext)
  const debug = useDebug('preview:image')

  const orientation = useTypedSelector(
    (state) => state.creation.device.orientation
  )

  const position = getPosition(
    containerSize,
    ctx.frame,
    ctx.video,
    orientation,
    debug
  )

  const imgSrc = getImgSrc(layer, ctx)

  return (
    <img
      className={styles.image}
      src={imgSrc}
      style={positionAsCSS(position)}
      alt=''
    />
  )
}

function getImgSrc(layer: ImageProps['layer'], ctx: PreviewInterface): string {
  if (layer === 'matte') return ctx.matteSrc
  if (layer === 'device') return ctx.deviceSrc
  return ctx.backgroundSrc
}

function getPosition(
  containerSize: Size,
  frame: ScreenPosition,
  _video: Size,
  orientation: IoOrientation,
  debug: debug.Debugger
) {
  const frameVertical = {
    width: containerSize.height * (frame.width / frame.height),
    height: containerSize.height,
  }
  const frameHorizontal = {
    width: containerSize.width,
    height: containerSize.width * (frame.height / frame.width),
  }

  const frameSize =
    orientation === IoOrientation.portrait
      ? frameVertical.width > containerSize.width
        ? frameHorizontal
        : frameVertical
      : frameHorizontal.height > containerSize.height
      ? frameVertical
      : frameHorizontal

  debug('FrameSize: %o', frameSize)

  const textureSize = frameSize.width / frame.width
  debug('TextureSize: %s', textureSize)

  let width = textureSize
  let height = textureSize
  if (textureSize > MAX_TEXTURE_SIZE) {
    debug(
      'TextureSize is bigger than MAX_TEXTURE_SIZE using: %s',
      MAX_TEXTURE_SIZE
    )
    width = MAX_TEXTURE_SIZE
    height = MAX_TEXTURE_SIZE
  }

  const position = {
    width,
    height,
    left: containerSize.width * 0.5 - width * frame.left,
    top: containerSize.height * 0.5 - height * frame.top,
  }
  debug('Position: %o', position)
  return position
}
