import React from 'react'
import { useMobile } from './hooks'

/**
 * Renders the given children if the condition returns true
 */
export const If: React.FC<{ condition: boolean }> = (props) => {
  if (props.condition === false) return null
  return <React.Fragment>{props.children}</React.Fragment>
}

/**
 * Renders the given children only if the screensize is on the mobile range
 */
export const OnlyMobile: React.FC = (props) => {
  const isMobile = useMobile()
  return <If condition={isMobile}>{props.children}</If>
}

/**
 * Renders the given children only if the screensize is on the desktop range
 */
export const OnlyDesktop: React.FC = (props) => {
  const isMobile = useMobile()
  return <If condition={!isMobile}>{props.children}</If>
}
