import { useEffect, useState } from 'react'
import { useDispatch } from '../store'
import { getCreation } from '../store/actions'
import { getCreationId } from '../utils'

/**
 * Hooks the window size to the state
 */

export function useCreationId(hasInit: boolean): [boolean, boolean, boolean] {
  const [hasFetch, setFetch] = useState(false)
  const [hasCreation, setCreation] = useState(false)
  const [creationId] = useState(getCreationId())
  const dispatch = useDispatch()

  useEffect(() => {
    if (!hasInit) return
    if (hasFetch) return
    if (hasCreation) return
    if (!creationId) {
      setFetch(true)
      setCreation(true)
      return
    }

    dispatch(getCreation(creationId))
      .then((result) => setCreation(result))
      .then(() => setFetch(true))
  }, [creationId, dispatch, hasCreation, hasFetch, hasInit])

  return [hasFetch, hasCreation, !!creationId]
}
