import { CSSClass, ScreenPosition } from '../interfaces'

/**
 * Returns a string combining the provided class names
 * @param classes - Css Classes
 */
export const asClassName = (...classes: CSSClass[]): string =>
  classes.filter(Boolean).join(' ')

/**
 * Returns a value to a CSS px string (10px)
 */
export const asPx = (val: number): string => `${val}px`

/**
 * Transforms the given position object to css pixels
 * @param pos - Position to transform
 */
export const positionAsCSS = (pos: ScreenPosition): ScreenPositionPx => {
  return {
    top: asPx(pos.top),
    left: asPx(pos.left),
    width: asPx(pos.width),
    height: asPx(pos.height),
  }
}

interface ScreenPositionPx {
  top: string
  left: string
  width: string
  height: string
}
