import { useCallback, useEffect, useState } from 'react'
import { Size } from '../interfaces'
import { useTypedSelector } from '../store'
import { hasWindow, useDebug } from '../utils'

/**
 * Returns the size of an element.
 * @todo use a ResizeObserver
 */

export function useElemSize<T extends HTMLElement>(
  elemReference: React.MutableRefObject<T | null>
): Size {
  const debug = useDebug('app:preview')
  const [size, setSize] = useState<Size>({ width: 0, height: 0 })
  const readyState = useTypedSelector((state) => state.readyState)

  const updateSize = useCallback(() => {
    if (elemReference?.current) {
      const { width, height } = elemReference.current.getBoundingClientRect()
      debug('width: %s - height: %s', width, height)
      setSize({ width, height })
    } else {
      setSize({ width: 0, height: 0 })
    }
  }, [debug, elemReference])

  // Recalculate on editable change (im assuming this will fire at least once)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateSize, [readyState])
  // Recalculate on window resize
  useEffect(() => {
    if (!hasWindow()) return () => undefined
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [updateSize])

  return size
}
